<template>
  <div>
    <h1>Leasing Calculator</h1>
    <div style="border: 1px solid black; margin-bottom: 15px;">
      <p>Payment = Depreciation + Finance charge.</p>
      <p>Depreciation = ((Capitalized Cost - Residual Value) / Number of Months)</p>
      <p>Finance Charge = ((Capitalized Cost + Residual Value) * Money Factor</p>
      <p><strong>Total formula</strong>: Payment = ((Capitalized Cost - Residual Value) / Number of Months) + ((Capitalized Cost + Residual Value) * Money Factor)</p>
    </div>

    <div style="margin-bottom: 15px;">
      <h3>User Inputs</h3>
      MSRP: <input type="number" v-model.number="msrp" /><br>
      Captialized Cost: <input type="number" v-model.number="capitalizedCost" /><br>
      Residual Value (whole number) <input type="number" v-model.number="residualValuePercentage" /><br>
      Number of Months <input type="number" v-model.number="numberOfMonths" /><br>
      Interest Rate (whole number) <input type="number" v-model.number="interestRate" /><br>
      Money Factor Divisor <input type="number" v-model.number="moneyFactorDivisor" /><br>
      Tax Rate <input type="number" v-model.number="taxRate" />
    </div>
    <div>
      <h3>Calculated Values Per Month</h3>
      <p>Residual Value: ${{ residualValue }}</p>
      <p>Depreciation: ${{ depreciation }}</p>
      <p>Finance Charge: ${{ financeCharge }}</p>
      <p>Money Factor: {{ moneyFactor }}</p>
      <p>Monthly Payment (w/o tax): {{ monthlyPayment }}</p>
      <p>Monthly Taxes: {{ monthlyTaxes }}</p>
      <h2>Payment: ${{ totalPayment }} / Month</h2>
    </div>
  </div>
</template>
<style>
  .v-application {
    background: #fff !important;
    color: black !important;
  }
  input {
    border: 1px solid black;
  }
</style>
<script>
// Vue.filter('toCurrencyWithDecimal', function (value) {
//   if (typeof value !== "number") {
//     return value;
//   }
//   var formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//   });
//   return formatter.format(value);
// })
  export default {
    data () {
      return {
        msrp: 0,
        capitalizedCost: 0,
        residualValuePercentage: 0,
        numberOfMonths: 0,
        interestRate: 0,
        taxRate: 0,
        moneyFactorDivisor: 2400
      }
    },
    computed: {
      residualValue() {
        return this.msrp * (this.residualValuePercentage / 100)
      },
      depreciation() {
        return ((this.capitalizedCost - this.residualValue) / this.numberOfMonths)
      },
      moneyFactor() {
        return this.interestRate / this.moneyFactorDivisor
      },
      financeCharge() {
        return ((this.capitalizedCost + this.residualValue) * this.moneyFactor)
      },
      monthlyPayment() {
        return (this.depreciation + this.financeCharge)
      },
      monthlyTaxes() {
        return this.monthlyPayment * (this.taxRate / 100)
      },
      totalPayment() {
        return this.monthlyPayment + this.monthlyTaxes
      }
    }
  }
</script>
